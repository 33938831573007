<template>
  <LiefengModal
      title="管理标签"
      :fullscreen="fullscreen"
      :value="value"
      :toggle="toggleType1"
  >
    <template v-slot:contentarea>
      <div class="container">
        <div class="title">勾选了哪些标签，该信息将在哪个标签菜单下显示</div>
        <div class="header">
          <Checkbox
              :indeterminate="indeterminate"
              :value="checkAll"
              @click.prevent.native="handleCheckAll">全选/反选
          </Checkbox>
        </div>
        <div class="main">
          <CheckboxGroup v-model="list" class="group" @on-change="checkAllGroupChange">
            <brack v-for="(item,index) in labelList" :key="index">
              <Checkbox :label='item.labelName'></Checkbox>
            </brack>
          </CheckboxGroup>
        </div>
      </div>
    </template>
    <template v-slot:toolsbar>
      <Button type="primary" id="determine" @click="determine">确定</Button>
      <Button type="primary" @click="flagDown">关闭</Button>
    </template>
  </LiefengModal>
</template>

<script lang="js">
import LiefengModal from "@/components/LiefengModal";

export default {
  name: "labelToggle",
  props: {
    value: Boolean,
    fullscreen: Boolean,
    flagType: Function,
    toggleType1: {
      type: Boolean
    },
  },
  data() {
    return {
      bool: '', //全选和取消全选
      list: [], //保存数组
      listCode: [], //编码
      labelList: [],
      indeterminate: false,
      checkAll: false,
      labelId: '',  //单个信息的id
      labelObj: {}, //字典
    }
  },
  methods: {
    flagDown() {
      // this.showMe2 = false;
      this.$emit('click', false)
    },
    //全选
    handleCheckAll() {
      if (this.indeterminate) {
        this.checkAll = false;
      } else {
        this.checkAll = !this.checkAll;
      }
      this.indeterminate = false;

      if (this.checkAll) {
        this.listCode = []
        this.labelList.map(item => {
          //把全部编号放进去
          this.list.push(item.labelName)
          this.listCode.push(item.labelCode)
        });
      } else {
        this.list = [];
        this.listCode = [];
      }

      // console.log(this.list, 'list我是选中的')
      // console.log(this.listCode, '我是全部的编号')
    },
    checkAllGroupChange(data) {
      if (data.length === this.labelList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },

    //确定
    determine() {
      this.listCode = []
      this.labelList.map(item => {
        this.labelObj[item.labelName] = item.labelCode
      })
      this.list.map(name => {
        this.labelList.map(item => {
          if (name === item.labelName) {
            this.listCode.push(item.labelCode)
          }
        })
      })
      if (this.list.length === 0) {
        this.$Message.warning({
          background: true,
          content: '请勾选标签！',
        });
        return
      } else {
        //更新单个聚合类的标签
        this.$post("/old/api/pc/information/polymerization/addLabelById", {  //fuck
          id: this.labelId,
          labelCodes: this.listCode && this.listCode.length > 0 ? this.listCode.join() : ''
        })
            .then((res) => {
              this.loading = false;
              if (res.code == 200) {
                // console.log(res, '更新单个聚合类的标签')
                this.flagDown()
                this.$emit('upData')
                this.$Message.destroy()
                this.$Message.success({
                  background: true,
                  content: '保存成功',
                });
              } else {
                this.$Message.destroy()
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            }).catch(err => {
          console.log(err)
          this.$Message.error({
            background: true,
            content: "获取数据失败。请重试",
          });
        })
      }
    },
    save(id) {
      this.labelId = id
      //获取标签
      this.$get("/datamsg/api/pc/platform/label/selectPlatform", {  //fuck
        labelType: 1
      })
          .then((res) => {
            this.loading = false;
            if (res.code == 200 && res.dataList) {
              // console.log(res, '获取标签')
              this.labelList = res.dataList.map(item => {
                return {
                  labelName: item.labelName,
                  labelCode: item.labelCode
                }
              })
              console.log(this.labelList, 'labelList')
              this.$Message.destroy()
            } else {
              this.$Message.destroy()
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          })
          .then((res) => {
            //查询单个信息聚合类标签
            this.$get('/old/api/pc/information/polymerization/queryLabelById', {
              id: id
            }).then(res => {
              if (res.code == 200 && res.dataList) {
                // console.log(res, '查询单个信息聚合类标签')
                if (res.dataList && res.dataList.length > 0) {
                  res.dataList.map(item => {
                    this.list.push(item.labelName)
                  })
                } else {
                  this.list = []
                }
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                  duration: 1
                })
              }
            })
          }).catch(err => {
        console.log(err)
        this.$Message.error({
          background: true,
          content: "获取数据失败。请重试",
        });
      })


    }
  },
  computed: {},
  components: {
    LiefengModal
  }
}
</script>

<style scoped lang="less">
#determine {
  margin-right: 10px;
}

.title {
  margin-left: 20px;
}

.main {
  margin: 20px 0;
  display: flex;
  justify-content: center;

  .group {
    display: flex;
    width: 100%;
    margin: 0 20px;
    flex-wrap: wrap;

    > brack {
      width: 33.333%;
      margin-bottom: 10px;
    }
  }
}

.header {
  display: flex;
  margin: 20px;
  >Button:first-child {
    margin-right: 20px;
  }
}
</style>
